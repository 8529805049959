import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keyword' content={keywords} />
    </Helmet>
  )
}
Meta.defaultProps = {
  title: 'The Web Developers',
  keywords:
    'marketing, website hosting, website development, programming, website design, mobile app development',
  description: 'One Stop Shop for Website, Software, and Mobile Development.',
}

export default Meta
