import React, { useEffect } from 'react'

// Assets
import classes from './BlogScreen.module.css'

// Redux

import { getBlogs } from '../../store/actions/blogActions'
import { useDispatch, useSelector } from 'react-redux'

// My Components
import CenterContainer from '../../components/CenterContainer'
import ArticleContainer from '../../components/ArticleContainer'
import Loader from '../../components/Loader'
import Meta from '../../components/Meta'

const BlogScreen = () => {
  const dispatch = useDispatch()

  const blogList = useSelector((state) => state.blogList)
  const { blogs } = blogList

  useEffect(() => {
    dispatch(getBlogs())
  }, [dispatch])
  return (
    <div className={classes.screen_container}>
      <Meta
        title='Blog | The Web Developers'
        description='Learn about Software, Website Development, Computer Programming, and much more'
      />
      <CenterContainer>
        <div className={classes.blogs_container}>
          {blogs ? (
            blogs.map((articleElement, idx) => (
              <ArticleContainer
                key={idx}
                category={articleElement.category}
                title={articleElement.title}
                imageLOC={articleElement.images[0]}
                description={articleElement.description}
                link={`/blog/${articleElement._id}`}
                contentMargin={articleElement.contentMargin}
              />
            ))
          ) : (
            <Loader />
          )}
        </div>
      </CenterContainer>
    </div>
  )
}

export default BlogScreen
