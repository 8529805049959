import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import {
  createSite,
  deleteSite,
  getSites,
} from '../../store/actions/siteActions'
import { CREATE_SITE_RESET } from '../../store/constants/siteConstants'

// My Components
import DetailList from '../../components/DetailList'
import MyButton from '../../components/MyButton'
import Loader from '../../components/Loader'
import Table from '../../components/Table'
import TBody from '../../components/TBody'
import Tr from '../../components/Tr'
import Td from '../../components/Td'
const SiteListContainer = (props) => {
  const { history } = props
  const dispatch = useDispatch()

  const siteCreate = useSelector((state) => state.siteCreate)
  const {
    loading: loadingCreateSite,
    success: successCreateSite,
    site: createdSite,
  } = siteCreate

  const siteList = useSelector((state) => state.siteList)
  const { loading: loadingSites, sites } = siteList

  const siteDelete = useSelector((state) => state.siteDelete)
  const {
    loading: loadingDelete,
    success: successDelete,
    // error: errorDelete,
  } = siteDelete

  useEffect(() => {
    dispatch({
      type: CREATE_SITE_RESET,
    })

    dispatch(getSites())

    if (successCreateSite) {
      history.push(`/admin/site/${createdSite._id}/edit`)
    }
  }, [dispatch, history, successCreateSite, createdSite, successDelete])

  const createSiteHandler = () => {
    dispatch(createSite())
  }

  const deleteSiteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteSite(id))
    }
  }
  return (
    <div>
      <h2>Upload A Site</h2>

      <MyButton
        content='Create A Site'
        variant='func'
        to={createSiteHandler}
        outMargin='15px'
        direction='left'
      />
      <MyButton
        content='Go To Sites'
        outMargin='15px'
        direction='left'
        to='/ourwork'
      />
      {loadingCreateSite ? <Loader /> : null}
      {loadingSites ? (
        <Loader />
      ) : (
        <div>
          <h2>Existing sites</h2>
          <Table fixed>
            <TBody>
              {sites ? (
                sites.map((siteElement, index) => (
                  <DetailList
                    key={index}
                    label='Existing Sites'
                    content={siteElement}
                    editLoc='site'
                    buttons={[
                      {
                        link: siteElement._id,
                        content: 'Edit',
                      },
                      {
                        variant: 'func',
                        to: () => deleteSiteHandler(siteElement._id),
                        content: 'Del',
                      },
                    ]}
                  />
                ))
              ) : (
                <Tr>
                  <Td>
                    <Loader />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          {loadingDelete ? <Loader /> : null}
        </div>
      )}
    </div>
  )
}

export default withRouter(SiteListContainer)
