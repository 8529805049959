import { combineReducers } from 'redux'
import {
  siteListReducer,
  siteCreateReducer,
  siteDeleteReducer,
  siteUpdateReducer,
  siteDetailsReducer,
} from './siteReducers'
import { userLoginReducer } from './userReducers'
import {
  blogListReducer,
  blogCreateReducer,
  blogUpdateReducer,
  blogDetailsReducer,
  blogDeleteReducer,
} from './blogReducers'

import {
  courseListReducer,
  courseCreateReducer,
  courseDeleteReducer,
  courseDetailsReducer,
  courseUpdateReducer,
} from './courseReducers'

import {
  hostCreateReducer,
  hostListReducer,
  hostDetailsReducer,
  hostDeleteReducer,
  hostUpdateReducer,
} from './hostingReducers'
import {
  hostFolderCreateReducer,
  hostFolderListReducer,
  hostFolderDetailsReducer,
  hostFolderDeleteReducer,
  hostFolderUpdateReducer,
} from './hostFolderReducers'

import { captchaReducer } from './captchaReducers'

export default combineReducers({
  siteList: siteListReducer,
  siteDetails: siteDetailsReducer,
  siteCreate: siteCreateReducer,
  siteDelete: siteDeleteReducer,
  siteUpdate: siteUpdateReducer,
  userLogin: userLoginReducer,
  blogList: blogListReducer,
  blogCreate: blogCreateReducer,
  blogUpdate: blogUpdateReducer,
  blogDetails: blogDetailsReducer,
  blogDelete: blogDeleteReducer,
  courseList: courseListReducer,
  courseCreate: courseCreateReducer,
  courseUpdate: courseUpdateReducer,
  courseDetails: courseDetailsReducer,
  courseDelete: courseDeleteReducer,
  hostCreate: hostCreateReducer,
  hostList: hostListReducer,
  hostDetails: hostDetailsReducer,
  hostDelete: hostDeleteReducer,
  hostUpdate: hostUpdateReducer,
  hostFolder: hostFolderCreateReducer,
  hostFolderList: hostFolderListReducer,
  hostFolderDetails: hostFolderDetailsReducer,
  hostFolderDelete: hostFolderDeleteReducer,
  hostFolderUpdate: hostFolderUpdateReducer,
  captcha: captchaReducer,
})
