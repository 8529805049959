import React from 'react'

// My Components
// Assets
import classes from './ThankYouMessage.module.css'
const ThankYouMessage = (props) => {
  return (
    <div className={classes.popup_container}>
      <h2>Thank You For Choosing Us!</h2>
      <p style={{ margin: '20px 5px' }}>
        One of our team members will contact you shortly.
      </p>
    </div>
  )
}

export default ThankYouMessage
