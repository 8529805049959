export const GET_COURSES_REQUEST = 'GET_COURSES_REQUEST'
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS'
export const GET_COURSES_FAIL = 'GET_COURSES_FAIL'

export const CREATE_COURSE_REQUEST = 'CREATE_COURSE_REQUEST'
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS'
export const CREATE_COURSE_FAIL = 'CREATE_COURSE_FAIL'
export const CREATE_COURSE_RESET = 'CREATE_COURSE_RESET'

export const COURSE_DETAILS_REQUEST = 'COURSE_DETAILS_REQUEST'
export const COURSE_DETAILS_SUCCESS = 'COURSE_DETAILS_SUCCESS'
export const COURSE_DETAILS_FAIL = 'COURSE_DETAILS_FAIL'

export const UPDATE_COURSE_REQUEST = 'UPDATE_COURSE_REQUEST'
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS'
export const UPDATE_COURSE_FAIL = 'UPDATE_COURSE_FAIL'
export const UPDATE_COURSE_RESET = 'UPDATE_COURSE_RESET'

export const DELETE_COURSE_REQUEST = 'DELETE_COURSE_REQUEST'
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS'
export const DELETE_COURSE_FAIL = 'DELETE_COURSE_FAIL'
