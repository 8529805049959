export const GET_HOST_FOLDERS_REQUEST = 'GET_HOST_FOLDERS_REQUEST'
export const GET_HOST_FOLDERS_SUCCESS = 'GET_HOST_FOLDERS_SUCCESS'
export const GET_HOST_FOLDERS_FAIL = 'GET_HOST_FOLDERS_FAIL'

export const CREATE_HOST_FOLDER_REQUEST = 'CREATE_HOST_FOLDER_REQUEST'
export const CREATE_HOST_FOLDER_SUCCESS = 'CREATE_HOST_FOLDER_SUCCESS'
export const CREATE_HOST_FOLDER_FAIL = 'CREATE_HOST_FOLDER_FAIL'
export const CREATE_HOST_FOLDER_RESET = 'CREATE_HOST_FOLDER_RESET'

export const HOST_FOLDER_DETAILS_REQUEST = 'HOST_FOLDER_DETAILS_REQUEST'
export const HOST_FOLDER_DETAILS_SUCCESS = 'HOST_FOLDER_DETAILS_SUCCESS'
export const HOST_FOLDER_DETAILS_FAIL = 'HOST_FOLDER_DETAILS_FAIL'

export const UPDATE_HOST_FOLDER_REQUEST = 'UPDATE_HOST_FOLDER_REQUEST'
export const UPDATE_HOST_FOLDER_SUCCESS = 'UPDATE_HOST_FOLDER_SUCCESS'
export const UPDATE_HOST_FOLDER_FAIL = 'UPDATE_HOST_FOLDER_FAIL'
export const UPDATE_HOST_FOLDER_RESET = 'UPDATE_HOST_FOLDER_RESET'

export const DELETE_HOST_FOLDER_REQUEST = 'DELETE_HOST_FOLDER_REQUEST'
export const DELETE_HOST_FOLDER_SUCCESS = 'DELETE_HOST_FOLDER_SUCCESS'
export const DELETE_HOST_FOLDER_FAIL = 'DELETE_HOST_FOLDER_FAIL'
