import axios from 'axios'
import {
  GET_HOST_FOLDERS_REQUEST,
  GET_HOST_FOLDERS_SUCCESS,
  GET_HOST_FOLDERS_FAIL,
  CREATE_HOST_FOLDER_REQUEST,
  CREATE_HOST_FOLDER_SUCCESS,
  CREATE_HOST_FOLDER_FAIL,
  UPDATE_HOST_FOLDER_RESET,
  UPDATE_HOST_FOLDER_REQUEST,
  UPDATE_HOST_FOLDER_SUCCESS,
  UPDATE_HOST_FOLDER_FAIL,
  HOST_FOLDER_DETAILS_REQUEST,
  HOST_FOLDER_DETAILS_SUCCESS,
  HOST_FOLDER_DETAILS_FAIL,
  CREATE_HOST_FOLDER_RESET,
  DELETE_HOST_FOLDER_REQUEST,
  DELETE_HOST_FOLDER_SUCCESS,
  DELETE_HOST_FOLDER_FAIL,
} from '../constants/hostFolderConstants'

export const createHostFolder = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_HOST_FOLDER_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post('/api/hosting/upload', {}, config)

    dispatch({
      type: CREATE_HOST_FOLDER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CREATE_HOST_FOLDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getHostFolders = () => async (dispatch) => {
  try {
    dispatch({ type: GET_HOST_FOLDERS_REQUEST })

    const { data } = await axios.get('/api/hosting/upload')

    dispatch({
      type: GET_HOST_FOLDERS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_HOST_FOLDERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getHostFolderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: HOST_FOLDER_DETAILS_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/hosting/upload/${id}`, config)

    dispatch({
      type: HOST_FOLDER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: HOST_FOLDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteHostFolder = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_HOST_FOLDER_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.delete(`/api/hosting/upload/${id}`, config)

    dispatch({
      type: DELETE_HOST_FOLDER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DELETE_HOST_FOLDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateHostFolder = (hostFolder) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_HOST_FOLDER_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/hosting/upload/${hostFolder._id}`,
      hostFolder,
      config
    )

    dispatch({
      type: UPDATE_HOST_FOLDER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: UPDATE_HOST_FOLDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
