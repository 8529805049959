import React, { useEffect } from 'react'
// Assets
// import classes from './CourseScreen.module.css'
// Redux

import { useDispatch, useSelector } from 'react-redux'
import { getCourses } from '../../store/actions/courseActions'

// Assets
import classes from './CourseScreen.module.css'

// My Components
import CenterContainer from '../../components/CenterContainer'
import ArticleContainer from '../../components/ArticleContainer'
import Loader from '../../components/Loader'
import Meta from '../../components/Meta'

const CourseScreen = () => {
  const dispatch = useDispatch()

  const courseList = useSelector((state) => state.courseList)
  const { courses } = courseList

  useEffect(() => {
    dispatch(getCourses())
  }, [dispatch])

  return (
    <div className={classes.screen_container}>
      <Meta
        title='Courses | The Web Developers'
        description='Learn about Software, Website Development, Computer Programming, and much more'
      />

      <CenterContainer>
        <div className={classes.courses_container}>
          {courses ? (
            courses.map((articleElement, idx) => (
              <ArticleContainer
                key={idx}
                title={articleElement.title}
                description={articleElement.description}
                imageLOC={articleElement.files[0]}
                link={`/course/${articleElement._id}`}
                linkText='Open Course'
              />
            ))
          ) : (
            <Loader />
          )}
        </div>
      </CenterContainer>
    </div>
  )

  // <div className={classes.screen_container}>
  //   <div style={{ margin: 100 }}>
  //     <button onClick={() => downloadImage(files)}>Download</button>
  //   </div>
  // </div>
}

export default CourseScreen
