import React from 'react'
import classes from './SEOLandingScreen.module.css'
import Meta from '../../components/Meta'
import ImageBanner from '../../components/ImageBanner'
import CenterContainer from '../../components/CenterContainer'
import MyButton from '../../components/MyButton'
import landing_picture from '../../assets/SEO By The Web Developers - Graphic.png'
import SEOQuoteScreen from '../SEOQuoteScreen'

const index = (props) => {
  const { history } = props
  return (
    <div className={classes.screen_container}>
      <Meta
        title='The Web Developers'
        description='One Stop Shop for Website, Software, and Mobile Development.'
      />
      <ImageBanner
        imageLOC={landing_picture}
        label='Search Engine Optimization'
        altText='Home page Banner'
        bgOpacity
        opacity={0.5}
        bgColor='#f2f2f2'
      />
      <CenterContainer bgPadding=''>
        <SEOQuoteScreen quoteScreenHistory={history} />
      </CenterContainer>
    </div>
  )
}

export default index
