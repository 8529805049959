import {
  GET_HOSTS_REQUEST,
  GET_HOSTS_SUCCESS,
  GET_HOSTS_FAIL,
  CREATE_HOST_REQUEST,
  CREATE_HOST_SUCCESS,
  CREATE_HOST_FAIL,
  UPDATE_HOST_RESET,
  UPDATE_HOST_REQUEST,
  UPDATE_HOST_SUCCESS,
  UPDATE_HOST_FAIL,
  HOST_DETAILS_REQUEST,
  HOST_DETAILS_SUCCESS,
  HOST_DETAILS_FAIL,
  CREATE_HOST_RESET,
  DELETE_HOST_REQUEST,
  DELETE_HOST_SUCCESS,
  DELETE_HOST_FAIL,
} from '../constants/hostingConstants'

export const hostCreateReducer = (state = { host: {} }, action) => {
  switch (action.type) {
    case CREATE_HOST_REQUEST:
      return { loading: true, hosting: {} }
    case CREATE_HOST_SUCCESS:
      return { loading: false, success: true, hosting: action.payload }
    case CREATE_HOST_FAIL:
      return { loading: false, error: action.payload }
    case CREATE_HOST_RESET:
      return {}
    default:
      return state
  }
}
export const hostListReducer = (state = { hosts: [] }, action) => {
  switch (action.type) {
    case GET_HOSTS_REQUEST:
      return { loading: true, hosts: [] }
    case GET_HOSTS_SUCCESS:
      return { loading: false, hosts: action.payload }
    case GET_HOSTS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const hostDetailsReducer = (state = { hosting: {} }, action) => {
  switch (action.type) {
    case HOST_DETAILS_REQUEST:
      return { loading: true, ...state }
    case HOST_DETAILS_SUCCESS:
      return { loading: false, success: true, hosting: action.payload }
    case HOST_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const hostDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_HOST_REQUEST:
      return { loading: true }
    case DELETE_HOST_SUCCESS:
      return { loading: false, success: true }
    case DELETE_HOST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const hostUpdateReducer = (state = { hosting: {} }, action) => {
  switch (action.type) {
    case UPDATE_HOST_REQUEST:
      return { loading: true, hosting: {} }
    case UPDATE_HOST_SUCCESS:
      return { loading: false, success: true, hosting: action.payload }
    case UPDATE_HOST_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_HOST_RESET:
      return { hosting: {} }
    default:
      return state
  }
}
