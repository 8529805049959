import React, { useEffect } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'

// My Components
import ImageBanner from '../../components/ImageBanner'
import CenterContainer from '../../components/CenterContainer'
import MyButton from '../../components/MyButton'

// Parts of this screen
import BlogListContainer from './BlogListContainer'
import CourseListContainer from './CourseListContainer'

// Assets
import classes from './AdminScreen.module.css'
import landing_bck from '../../assets/landing_bck.jpg'
import { logout } from '../../store/actions/userActions'
import SiteListContainer from './SiteListContainer'

const AdminScreen = (props) => {
  const { history } = props
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
  }, [userInfo, history])

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <div className={classes.screen_container}>
      <ImageBanner
        imageLOC={landing_bck}
        bgOpacity
        opacity={0.3}
        label='Administrator'
      />

      <CenterContainer>
        <MyButton
          content='Logout'
          variant='func'
          to={logoutHandler}
          outMargin='15px'
          direction='left'
        />
        <SiteListContainer />
        <BlogListContainer />
        <CourseListContainer />
      </CenterContainer>
    </div>
  )
}

export default AdminScreen
