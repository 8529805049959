import {
  GET_HOST_FOLDERS_REQUEST,
  GET_HOST_FOLDERS_SUCCESS,
  GET_HOST_FOLDERS_FAIL,
  CREATE_HOST_FOLDER_REQUEST,
  CREATE_HOST_FOLDER_SUCCESS,
  CREATE_HOST_FOLDER_FAIL,
  UPDATE_HOST_FOLDER_RESET,
  UPDATE_HOST_FOLDER_REQUEST,
  UPDATE_HOST_FOLDER_SUCCESS,
  UPDATE_HOST_FOLDER_FAIL,
  HOST_FOLDER_DETAILS_REQUEST,
  HOST_FOLDER_DETAILS_SUCCESS,
  HOST_FOLDER_DETAILS_FAIL,
  CREATE_HOST_FOLDER_RESET,
  DELETE_HOST_FOLDER_REQUEST,
  DELETE_HOST_FOLDER_SUCCESS,
  DELETE_HOST_FOLDER_FAIL,
} from '../constants/hostFolderConstants'

export const hostFolderCreateReducer = (state = { hostFolder: {} }, action) => {
  switch (action.type) {
    case CREATE_HOST_FOLDER_REQUEST:
      return { loading: true, hostFolder: {} }
    case CREATE_HOST_FOLDER_SUCCESS:
      return { loading: false, success: true, hostFolder: action.payload }
    case CREATE_HOST_FOLDER_FAIL:
      return { loading: false, error: action.payload }
    case CREATE_HOST_FOLDER_RESET:
      return {}
    default:
      return state
  }
}
export const hostFolderListReducer = (state = { hostFolders: [] }, action) => {
  switch (action.type) {
    case GET_HOST_FOLDERS_REQUEST:
      return { loading: true, hostFolders: [] }
    case GET_HOST_FOLDERS_SUCCESS:
      return { loading: false, hostFolders: action.payload }
    case GET_HOST_FOLDERS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const hostFolderDetailsReducer = (
  state = { hostFolder: {} },
  action
) => {
  switch (action.type) {
    case HOST_FOLDER_DETAILS_REQUEST:
      return { loading: true, ...state }
    case HOST_FOLDER_DETAILS_SUCCESS:
      return { loading: false, success: true, hostFolder: action.payload }
    case HOST_FOLDER_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const hostFolderDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_HOST_FOLDER_REQUEST:
      return { loading: true }
    case DELETE_HOST_FOLDER_SUCCESS:
      return { loading: false, success: true }
    case DELETE_HOST_FOLDER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const hostFolderUpdateReducer = (state = { hostFolder: {} }, action) => {
  switch (action.type) {
    case UPDATE_HOST_FOLDER_REQUEST:
      return { loading: true, hostFolder: {} }
    case UPDATE_HOST_FOLDER_SUCCESS:
      return { loading: false, success: true, hostFolder: action.payload }
    case UPDATE_HOST_FOLDER_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_HOST_FOLDER_RESET:
      return { hostFolder: {} }
    default:
      return state
  }
}
