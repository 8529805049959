import React, { useState, useEffect, useRef } from 'react'
import classes from './HomeScreen.module.css'
import axios from 'axios'
import Slide from 'react-reveal/Slide'
import { useLocation } from 'react-router-dom'

// My Components
import CenterContainer from '../../components/CenterContainer'
import Card from '../../components/Card'
import MyButton from '../../components/MyButton'
import Meta from '../../components/Meta'
import { eventTrack } from '../../components/functions'
import QuoteScreen from '../QuoteScreen'
import UploadTest from './UploadTest'
import UploadTestTwo from './UploadTestTwo'

// PopUps
// Assets
import landing_vid from '../../assets/Home Screen_02.mp4'
import FormField from '../../components/FormField'
import Loader from '../../components/Loader'
import poster from '../../assets/coding_background.png'
// console.log(process.env)
// console.log(process.env.REACT_GA_TEST)
const HomeScreen = (props) => {
  const { history } = props
  // Testing Below
  // Testing Below
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    // phone: '',
  })
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  // Arrays
  const otherServicesArray = [
    ['Marketing', 'Hosting', 'Logo Design'],
    ['Data Collection', 'API Development', 'Database Management'],
  ]

  // Form State
  const formConfig = {
    name: {
      type: 'input',
      config: { type: 'text', placeholder: 'Full Name' },
    },
    email: {
      type: 'input',
      config: { type: 'email', placeholder: 'Email' },
    },
  }

  // Prepare formState objects
  const formElements = []
  for (let key in formState) {
    formElements.push({
      id: key,
      setup: formConfig[key],
      value: formState[key],
    })
  }

  const inputChangedHandler = (event, inputIdentifier) => {
    formElements.forEach((formElement) => {
      if (inputIdentifier === formElement.id) {
        setFormState({
          ...formState,
          [inputIdentifier]: event.target.value,
        })
      }
    })
  }

  const submitGetStartedForm = async (e) => {
    e.preventDefault()
    setLoadingSubmit(true)

    const { name, email, phone } = formState
    try {
      await axios.post('/api/send', {
        name,
        email,
        phone,
      })
      console.log('Message Sent')
      // ensuring that paths are still the same to prevent a state change attempt when the screen has changed.
    } catch (error) {
      console.log('Message failed to send')
    }
  }
  // Testing
  // Testing Above Ends Here

  // TEsting for V3
  // TEsting for V3
  // TEsting for V3

  // Connecting to digital ocean api
  const submitHandler = async (e) => {
    try {
      const hosting = await axios.get(
        'https://api.digitalocean.com/v2/domains',
        {
          headers: {
            Authorization:
              'Bearer dop_v1_9fe730e2a552628c7c41b9d5ab781d6e42ac72cab65b34415eb42f69262cf91e',
          },
        }
      )
      // TBD TBD TBD TBD TBD
      // try {
      //   const hosting = await axios.post(
      //     'https://api.digitalocean.com/v2/domains',
      //     {
      //       headers: {
      //         Authorization:
      //           'Bearer dop_v1_9fe730e2a552628c7c41b9d5ab781d6e42ac72cab65b34415eb42f69262cf91e',
      //       },
      //     }
      //   )
      console.log('Message Sent')
      // ensuring that paths are still the same to prevent a state change attempt when the screen has changed.
    } catch (error) {
      console.log('Message failed to send', error.message)
    }
  }
  // TEsting for V3
  // TEsting for V3
  // TEsting for V3

  //
  // Timer for left side categories

  // Might not be nessecary

  useEffect(() => {
    var vid = document.getElementById('myVideo')
    // vid.load()
    vid.play()
  }, [])

  const [time, setTime] = useState(0)
  useEffect(() => {
    if (time < 7) {
      const timer1 = setInterval(() => {
        setTime(time + 1)
        clearInterval(timer1)
      }, 600)

      return () => {
        clearInterval(timer1)
      }
    }
    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    // and show will not change to true
  }, [time])
  // const myRef = (useRef < null) | (HTMLDivElement > null)
  const myRef = useRef(null)

  useEffect(() => {
    if (myRef && history.location.hash.includes('#quote_anchor')) {
      history.push('/')
      setTimeout(() => {
        myRef.current.scrollIntoView({
          behavior: 'smooth',
        })
      }, 200)
    }
  }, [myRef, history.location.hash])

  const getStartedHandler = (e) => {
    eventTrack('home', 'Home_Get_Started_Button_Click', 'live')
  }
  // Might not be nessecary
  // const getQuoteHandler = (e) => {
  //   e.preventDefault()

  //   eventTrack('home', 'Home_Get_Quote_Button_Click', 'test')
  // }
  const getStartedWithContentHandler = (e) => {
    e.preventDefault()
  }

  return (
    <div className={classes.screen_container}>
      {/* For a later version */}
      {/* <PopUp delay='7'>
        <NewsletterMessage />
      </PopUp> */}
      <Meta
        title='Home | The Web Developers'
        description='One Stop Shop for Website, Software, and Mobile Development.'
      />

      <div className={classes.landingPage}>
        <div className={classes.info_slide_container}>
          {/* {time > 2 && ( */}
          <div className={classes.info_slide}>
            <Slide left>
              <h3>Website Design</h3>
            </Slide>
          </div>
          {/* )} */}
          {/* {time > 4 && ( */}
          <div className={classes.info_slide}>
            <Slide left>
              <h3>Mobile App Design</h3>
            </Slide>
          </div>
          {/* )} */}

          {/* {time > 6 && ( */}
          <div className={classes.info_slide}>
            <Slide left>
              <h3>Software Dev</h3>
            </Slide>
          </div>
          {/* )} */}
        </div>
        <div className={classes.topBar}></div>
        {/* <div className={classes.tint}></div> */}

        <div className={classes.getStarted_container}>
          <video
            poster={poster}
            id='myVideo'
            webkit-playsInline
            webkit-playsinline='true'
            playsInline
            src={landing_vid}
            autoPlay={true}
            loop
            preload='auto'
            muted
            autobuffer
            controls={false}
          />
          {/* Awaiting Version 2.5 / 3 */}
          {/* <div className={classes.getStarted_submitform}>
            <form>
              {formElements.map((formElement) => (
                <FormField
                  key={formElement.id}
                  type={formElement.setup.type}
                  config={formElement.setup.config}
                  value={formElement.value}
                  changed={(event) =>
                    inputChangedHandler(event, formElement.id)
                  }
                />
              ))}
              {loadingSubmit && <Loader />}

              <MyButton
                styleVariant='clear'
                variant={formState.email ? 'func' : 'a'}
                to={
                  formState.email
                    ? getStartedWithContentHandler
                    : '/#quote_anchor'
                }
                tracking={getStartedHandler}
                direction='right'
                fontSize='1.75rem'
                color='white'
                bgHoverColor='#5178e9'
              >
                Get Started
                <i
                  className='fas fa-arrow-right'
                  style={{
                    fontSize: '3rem',
                    color: 'white',
                    padding: '0 10px',
                  }}
                ></i>
              </MyButton>
            </form>
          </div> */}
          <div className={classes.getStarted}>
            <MyButton
              styleVariant='clear'
              variant='a'
              to='/#quote_anchor'
              tracking={getStartedHandler}
              direction='right'
              fontSize='1.75rem'
              padding='5px 0'
              color='white'
              bgHoverColor='#5178e9'
            >
              Get Started
              <i
                className='fas fa-arrow-right'
                style={{ fontSize: '3rem', color: 'white', padding: '0 10px' }}
              ></i>
            </MyButton>
          </div>
        </div>
      </div>
      <CenterContainer bgColor='#f2f2f2' bgPadding='25px 0'>
        {/* Awaiting Version 3 / 3.5 */}
        {/* testing */}
        {/* <MyButton
              variant='func'
              content='test submit handler'
              to={submitHandler}
              direction='center'
            /> */}
        {/* <UploadTest props={props} /> */}
        {/* <UploadTestTwo /> */}

        <div className={classes.landing_featuredContainer}>
          <h1 className={classes.textBanner}>Why Choose Us?</h1>
          <div className={classes.firstCards_container}>
            <Card>
              <i
                className='fas fa-keyboard'
                style={{ fontSize: '4rem', color: '#4BB781' }}
              ></i>
              <h2>Intuitive, Creative, Professional</h2>
              <p>
                You get the best experience when choosing us for your website
                design and development
              </p>
            </Card>
            <Card margin='10px 0'>
              <i
                className='fas fa-shopping-cart'
                style={{
                  fontSize: '4rem',
                  color: '#3D5AAF',
                  margin: '10px 0',
                }}
              ></i>
              <h2>One Stop Shop</h2>
              <p>
                Marketing, Hosting, Website Development, and more all through
                one committed provider.
              </p>
            </Card>
            <Card>
              <i
                className='fas fa-dolly'
                style={{ fontSize: '4rem', color: '#4BB781' }}
              ></i>
              <h2>Hard Working</h2>
              <p>
                We strive to provide our customers quick and professional
                support​​
              </p>
            </Card>
          </div>
        </div>
      </CenterContainer>
      <div id='quote_anchor'></div>
      <div ref={myRef}></div>
      <QuoteScreen quoteScreenHistory={history} />
      <CenterContainer bgColor='#f2f2f2' bgPadding='40px 0'>
        <div className={classes.infoSection_container}>
          <div className={classes.infoSection_right}>
            <h3>Intuitive Design</h3>
            <p>
              We create intuitive websites that feature clean and modern
              designs. Every project starts with an outline of how we're going
              to achieve the desired outcome, and we stick to that schedule.
            </p>
            <h3>Worry Free</h3>
            <p>
              We are your full-service website development partner, and we take
              care of everything when it comes to web design. We can get you a
              domain name, build your site, and host it, all in one place.
            </p>
          </div>
        </div>
      </CenterContainer>
      <CenterContainer bgPadding='40px 0'>
        <div>
          <h2 className={classes.textBanner}>Other Services</h2>
          <div className={classes.firstCards_container}>
            {otherServicesArray[0].map((item, index) => (
              <Card key={index} variant='outline'>
                <h2>{item}</h2>
              </Card>
            ))}
          </div>
        </div>
      </CenterContainer>
    </div>
  )
}

export default HomeScreen
