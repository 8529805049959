export const GET_HOSTS_REQUEST = 'GET_HOSTS_REQUEST'
export const GET_HOSTS_SUCCESS = 'GET_HOSTS_SUCCESS'
export const GET_HOSTS_FAIL = 'GET_HOSTS_FAIL'

export const CREATE_HOST_REQUEST = 'CREATE_HOST_REQUEST'
export const CREATE_HOST_SUCCESS = 'CREATE_HOST_SUCCESS'
export const CREATE_HOST_FAIL = 'CREATE_HOST_FAIL'
export const CREATE_HOST_RESET = 'CREATE_HOST_RESET'

export const HOST_DETAILS_REQUEST = 'HOST_DETAILS_REQUEST'
export const HOST_DETAILS_SUCCESS = 'HOST_DETAILS_SUCCESS'
export const HOST_DETAILS_FAIL = 'HOST_DETAILS_FAIL'

export const UPDATE_HOST_REQUEST = 'UPDATE_HOST_REQUEST'
export const UPDATE_HOST_SUCCESS = 'UPDATE_HOST_SUCCESS'
export const UPDATE_HOST_FAIL = 'UPDATE_HOST_FAIL'
export const UPDATE_HOST_RESET = 'UPDATE_HOST_RESET'

export const DELETE_HOST_REQUEST = 'DELETE_HOST_REQUEST'
export const DELETE_HOST_SUCCESS = 'DELETE_HOST_SUCCESS'
export const DELETE_HOST_FAIL = 'DELETE_HOST_FAIL'
