export const GET_SITES_REQUEST = 'GET_SITES_REQUEST'
export const GET_SITES_SUCCESS = 'GET_SITES_SUCCESS'
export const GET_SITES_FAIL = 'GET_SITES_FAIL'

export const GET_SITE_DETAILS_REQUEST = 'GET_SITE_DETAILS_REQUEST'
export const GET_SITE_DETAILS_SUCCESS = 'GET_SITE_DETAILS_SUCCESS'
export const GET_SITE_DETAILS_FAIL = 'GET_SITE_DETAILS_FAIL'

export const CREATE_SITE_REQUEST = 'CREATE_SITE_REQUEST'
export const CREATE_SITE_SUCCESS = 'CREATE_SITE_SUCCESS'
export const CREATE_SITE_FAIL = 'CREATE_SITE_FAIL'
export const CREATE_SITE_RESET = 'CREATE_SITE_RESET'

export const DELETE_SITE_REQUEST = 'DELETE_SITE_REQUEST'
export const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS'
export const DELETE_SITE_FAIL = 'DELETE_SITE_FAIL'

export const UPDATE_SITE_REQUEST = 'UPDATE_SITE_REQUEST'
export const UPDATE_SITE_SUCCESS = 'UPDATE_SITE_SUCCESS'
export const UPDATE_SITE_FAIL = 'UPDATE_SITE_FAIL'
export const UPDATE_SITE_RESET = 'UPDATE_SITE_RESET'
