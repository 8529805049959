import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

// Assets
import classes from './BlogPostScreen.module.css'

// Redux
import { getBlogDetails } from '../../store/actions/blogActions'
import { useDispatch, useSelector } from 'react-redux'

// My Components
import CenterContainer from '../../components/CenterContainer'
import Loader from '../../components/Loader'
import MyButton from '../../components/MyButton'
import Meta from '../../components/Meta'

const BlogPostScreen = (props) => {
  const { match } = props
  const dispatch = useDispatch()
  const [isAdministrator, setIsAdministrator] = useState(false)

  const blogId = match.params.id
  const blogDetails = useSelector((state) => state.blogDetails)
  const { blog } = blogDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!blog || blog._id !== blogId) {
      dispatch(getBlogDetails(blogId))
    }
    if (userInfo && userInfo.isAdmin) {
      setIsAdministrator(true)
    }
  }, [dispatch, blog, blogId, userInfo])

  return (
    <div className={classes.screen_container}>
      <Meta
        title={`${blog ? blog.title : null} | The Web Developers`}
        description={
          blog.description
            ? blog.description
            : 'One Stop Shop for Website, Software, and Mobile Development.'
        }
      />
      <CenterContainer>
        {blog ? (
          <>
            {isAdministrator && (
              <MyButton
                content='Edit '
                outMargin='15px'
                direction='left'
                to={`/admin/blog/${blog._id}/edit`}
              />
            )}
            <div className={classes.post_container}>
              <ReactMarkdown source={blog.content} />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </CenterContainer>
    </div>
  )
}

export default BlogPostScreen
