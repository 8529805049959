import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { UPDATE_HOST_FOLDER_RESET } from '../../store/constants/hostFolderConstants'
import {
  getHostFolderDetails,
  updateHostFolder,
} from '../../store/actions/hostFolderActions'
import MyButton from '../../components/MyButton'

const UploadHostFolderScreen = (props) => {
  const { match, history } = props

  const [files, setFiles] = useState([])
  const [videos, setVideos] = useState([])

  const dispatch = useDispatch()

  const hostingId = match.params.id

  const hostFolderUpdate = useSelector((state) => state.hostFolderUpdate)

  const { success: successUpdate, hostFolder } = hostFolderUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: UPDATE_HOST_FOLDER_RESET })
    } else {
      if (!hostFolder || hostingId !== hostFolder._id) {
        dispatch(getHostFolderDetails(hostingId))
      } else {
        setFiles(hostFolder.files)
      }
    }
  }, [dispatch, hostFolder, history, hostingId, successUpdate])

  useEffect(() => {
    const picker = document.getElementById('picker')
    picker.addEventListener('change', (e) => {
      const fileArry = []
      // Process every single file
      for (var i = 0; i < picker.files.length; i++) {
        var file = picker.files[i]
        fileArry.push(file)
      }
      console.log(fileArry)
      setFiles(fileArry)
    })
  }, [])

  const submitHandler = () => {
    console.log('test')
    updateHostFolder(files)
    dispatch(
      updateHostFolder({
        _id: hostingId,
        files: files ? files : hostFolder.files,
        videos: videos ? videos : hostFolder.videos,
      })
    )
  }

  // Drop handler function to get all files

  // On button input change (picker), process it

  return (
    <>
      <h3>Choose Folder</h3>
      <div class='picker'>
        <input
          // ref={myRef}
          type='file'
          id='picker'
          webkitdirectory='true'
          multiple
        />
        {files ? console.log(files) : null}
        <MyButton content='Submit' variant='func' to={submitHandler} />
      </div>
    </>
  )
}

export default UploadHostFolderScreen
