import React from 'react'
import SlickSlider from '../../components/SlickSlider'
import classes from './OurWorkScreen.module.css'

const FeaturedSite = ({
  category,
  siteTitle,
  siteLink,
  siteImages,
  showLabel,
  siteDescription,
}) => {
  return (
    <div className={classes.divMain}>
      <div className={classes.text_container}>
        {showLabel ? (
          <h2 className={classes.label}>{category}</h2>
        ) : (
          <div className={classes.spacer}></div>
        )}
        <a
          className={showLabel ? classes.link_two : classes.link}
          href={siteLink}
        >
          {siteTitle}
        </a>
        <p className={classes.description}>{siteDescription}</p>
      </div>
      <SlickSlider images={siteImages} />
    </div>
  )
}

export default FeaturedSite
