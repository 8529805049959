import React, { useState } from 'react'
import axios from 'axios'

// Assets
import classes from './QuoteScreen.module.css'

// My Components
import CenterContainer from '../../components/CenterContainer'
import Card from '../../components/Card'
import MyButton from '../../components/MyButton'
import FormField from '../../components/FormField'
import Meta from '../../components/Meta'
import Loader from '../../components/Loader'
import PopUp from '../../components/PopUp'
import ThankYouMessage from '../../PopUps/ThankYouMessage'
import { eventTrack } from '../../components/functions'

const SEOQuoteScreen = (props) => {
  const { quoteScreenHistory } = props
  // const dispatch = useDispatch();
  const [popUpIsActive, setPopUpIsActive] = useState(false)
  const [infoMessage, setInfoMessage] = useState(null)
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    phone: '',
    service: 'SEO',
    // address: '',
    // typeOfBusiness: '',
  })
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  // Form State
  const formConfig = {
    name: {
      type: 'input',
      config: { type: 'text', placeholder: 'Full Name' },
    },
    email: {
      type: 'input',
      config: { type: 'email', placeholder: 'Email' },
    },
    phone: {
      type: 'input',
      config: { type: 'number', placeholder: 'Phone' },
    },
    service: {
      type: 'input',
      config: { type: 'input', placeholder: 'Website Consultation' },
    },
    // address: {
    //   type: 'input',
    //   config: { type: 'text', placeholder: 'Address' },
    // },
    // typeOfBusiness: {
    //   type: 'input',
    //   config: { type: 'text', placeholder: 'Type of business' },
    // },
  }
  // Tracking Handlers
  const getQuoteHandler = () => {
    eventTrack(
      'quote',
      'Quote_Submit_Button_Click_SEO_Landing_Page',
      'test_001'
    )
  }
  // Prepare formState objects
  const formElements = []
  for (let key in formState) {
    formElements.push({
      id: key,
      setup: formConfig[key],
      value: formState[key],
    })
  }

  const inputChangedHandler = (event, inputIdentifier) => {
    formElements.forEach((formElement) => {
      if (inputIdentifier === formElement.id) {
        setFormState({
          ...formState,
          [inputIdentifier]: event.target.value,
        })
      }
    })
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    setLoadingSubmit(true)
    const { name, email, phone, service } = formState
    if (
      email === undefined ||
      email === null ||
      email === '' ||
      email.length < 1
    ) {
      setInfoMessage('Please enter your Email')
      setLoadingSubmit(false)

      return
    }
    setInfoMessage(null)

    try {
      await axios.post('/api/send', {
        name,
        email,
        phone,
        service,
        // address,
        // typeOfBusiness,
      })
      console.log('Message Sent')
      // ensuring that paths are still the same to prevent a state change attempt when the screen has changed.
      if (quoteScreenHistory.location.pathname === '/seo')
        setPopUpIsActive(true)
    } catch (error) {
      console.log('Message failed to send')
    }
    if (quoteScreenHistory.location.pathname === '/seo') setLoadingSubmit(false)
  }

  return (
    <div className={classes.screen_container}>
      {popUpIsActive ? (
        <PopUp immediate>
          <ThankYouMessage />
        </PopUp>
      ) : null}

      <CenterContainer>
        <div className={classes.section_container}>
          <Card align='left'>
            <div className={classes.card_details}>
              <h4>Search Engine Optimization</h4>
              <p>Increase direct website traffic</p>
              <p>Call now: 239-671-7373</p>
              <form onSubmit={submitHandler}>
                {formElements.map((formElement) => (
                  <FormField
                    key={formElement.id}
                    type={formElement.setup.type}
                    config={formElement.setup.config}
                    value={formElement.value}
                    changed={(event) =>
                      inputChangedHandler(event, formElement.id)
                    }
                  />
                ))}
                {infoMessage && (
                  <p className={classes.redMessage}>{infoMessage}</p>
                )}
                {loadingSubmit ? (
                  <Loader />
                ) : (
                  <MyButton
                    content='Submit'
                    variant='submit'
                    tracking={getQuoteHandler}
                    style={{ margin: '10px 0' }}
                    styleVariant='clear'
                    hoverColor='#4bb781'
                    fontSize='1.3rem'
                  />
                )}
              </form>
            </div>
          </Card>
          <div className={classes.info_container}></div>
        </div>
      </CenterContainer>
    </div>
  )
}

export default SEOQuoteScreen
