import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

// Assets
import classes from './CoursePostScreen.module.css'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { getCourseDetails } from '../../store/actions/courseActions'

// My Components
import CenterContainer from '../../components/CenterContainer'
import Loader from '../../components/Loader'
import MyButton from '../../components/MyButton'
import Meta from '../../components/Meta'

const CoursePostScreen = (props) => {
  const { match } = props
  const dispatch = useDispatch()
  const [isAdministrator, setIsAdministrator] = useState(false)

  const courseId = match.params.id

  const courseDetails = useSelector((state) => state.courseDetails)
  const { course } = courseDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const downloadImage = async () => {
    window.open(
      `http://localhost:5001/api/download?files=${course.files}`,
      '_parent'
    )
  }

  useEffect(() => {
    if (!course || course._id !== courseId) {
      dispatch(getCourseDetails(courseId))
      console.log(course)
    }
    if (userInfo && userInfo.isAdmin) {
      setIsAdministrator(true)
    }
  }, [dispatch, course, courseId, userInfo])

  return (
    <div className={classes.screen_container}>
      <Meta
        title={`${course ? course.title : null} | The Web Developers`}
        description={
          course.description
            ? course.description
            : 'One Stop Shop for Website, Software, and Mobile Development.'
        }
      />
      <CenterContainer Horizontal justify='left'>
        {course ? (
          <div className={classes.post_container}>
            {isAdministrator && (
              <div>
                <MyButton
                  content='Edit post'
                  outMargin='15px'
                  direction='left'
                  to={`/admin/course/${course._id}/edit`}
                />
              </div>
            )}
            <ReactMarkdown source={course.markDown} />
            <div className={classes.images_container}>
              {course.files && (
                <div>
                  {course.files.map((file, idx) => (
                    <img
                      className={classes.course_image}
                      key={idx}
                      src={file}
                      alt={file}
                    />
                  ))}
                  {course.files.length < 1 ? null : (
                    <MyButton
                      content='Download Files'
                      variant='func'
                      to={() => downloadImage(course.files)}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </CenterContainer>
    </div>
  )
}

export default CoursePostScreen
